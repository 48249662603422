import React from 'react'
import './Avatar.css'

function Avatar() {
    return (
        <div className="hero-logo-circles">
            <img className="hero-logo-circle" src="/avatar/avatar.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-blue-50996d0762fb6d9ee13d6a52404dd01327fc542cc729777d532c9b633103c835.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-blue-semi-930c6662236ab84933e73083bc057b869b895bd36cef9e3e5254018fb61bc82a.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-green-8a75a5e7cc4f8426a47f5ebfe2459283d3554cfdcf8dc5f492fda0b26d2d6d79.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-green-semi-6b12059c6aea11bf60e8f3c89afdffb6b817913541a1ce72cbca29f733224984.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-orange-235189ed438049f084ca873f6ed2055c4127ddef84a55fa464053f34c10157e6.svg" alt="" />  
            <img className="hero-logo-circle" src="/avatar/index-portal-orange-semi-d9d13d5529ae8f3c91dc80b182cf14fc629118bbdac8851b36e102dd15652557.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-red-2f7737b286dff1537adff47b8201aa43e33cbe1e47bd528ca2189809917fe132.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-red-semi-5aec49dbe5d420f928cece7001b96267ee17a9d3577374b68b4fba47c547bd75.svg" alt="" />  
            <img className="hero-logo-circle" src="/avatar/index-portal-yellow-bf8ec5927e343b3a804448d37a799c9c346af320f30b3cdc019297938fe00300.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/index-portal-yellow-semi-af7a94ccf4768a81e6692d808f00e33ac88d9110f842d02c5ab211577686301a.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/p1.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/p2.svg" alt="" />
            <img className="hero-logo-circle" src="/avatar/p3.svg" alt="" />
        </div>
    )
}

export default Avatar
