import React from 'react'
import './App.css'

//Component
import Avatar from './components/Avatar/Avatar'
import Button from './components/Button/Button'
import Social from './components/Social/Social'
import TechIcon from './components/TechIcon/TechIcon'

function App() {
  return (
    <div className="app">
      {/* SECTION 1 */}
      <section className="section__1">
      <img src="/img/universe.svg" alt="" />
      <img src="/img/cat.svg" alt="" />
      <img src="/img/planet1.svg" alt="" />
      <img src="/img/planet2.svg" alt="" />

        <div className="intro">
          <p className="intro__one">
            <span>Hi there!</span> <br />
            <span>I'm Lav Singh</span>
          </p>

          <p className="intro__two">
            <span>I'm a software developer with a knowledge of</span> <br />
            <span>UI/UX design and specializing in React-JS</span>
          </p>

          <div className="btn">
            <Button icon="download" name="Resume" file="Resume.pdf" />
          </div>
        </div>
        <Avatar />
      </section>

      {/* SECTION 2 */}
      <section className="section__2">
        <img src="avatar/avatar_one.svg" alt=""/>

        <div className="about__section">
          <p className="about__heading">
              About
          </p>
          <p className="about__text">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </p>
          <div className="social__exp">
            <div className="social__handle">
              <p className="about__handle">Reach me out</p>
              <div className="icons">
                <Social icon="linkedin" url="https://www.linkedin.com/in/lav-singh-3110/" />
                <Social icon="github" url="https://github.com/lavkumarsingh" />
              </div>
            </div>
            <p className="about__exp">
                <span className="exp">1.5</span>
                <span className="exp__text">years of <br/> experience</span>
            </p>
          </div>
          <img src="avatar/signature.svg" alt=""/>
        </div>
        <img src="img/line_background.svg" alt=""/>

      </section>

      {/* SECTION 3 */}
      <section className="section__3">
        <img src="img/line_background.svg" alt=""/>
        
        <div className="skill__section">
          <p className="about__heading">
              My Skills
          </p>
          <div className="tech__icons">
            <TechIcon logo="nodejs" />
            <TechIcon logo="react" />
            <TechIcon logo="mongodb" />
            <TechIcon logo="expressjs" />
            <TechIcon logo="ethereum" />
            <TechIcon logo="truffle" />
            <TechIcon logo="web3" />
            <TechIcon logo="ipfs" />
            <TechIcon logo="html" />
            <TechIcon logo="css" />
            <TechIcon logo="javascript" />
          </div>
        </div>
        
        <img src="img/line_background.svg" alt=""/>
      </section>
    </div>
  );
}

export default App;
