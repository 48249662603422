import React from 'react'
import './Button.css'

function Button(props) {
    return (
        <div className="button">
            <a href={"/files/" + props.file} download>
                <button >
                    <img src={"/icons/" + props.icon + ".svg"} alt="" />
                    {props.name}
                </button>
            </a>
        </div>
    )
}

export default Button
