import React from 'react'
import './TechIcon.css'

function TechIcon(props) {
    return (
        <div className="tech__icon">
            <img src={"/icons/" + props.logo + ".svg"} alt=""/>
        </div>
    )
}

export default TechIcon
