import React from 'react'
import './Social.css'

function Social(props) {
    return (
        <div className="social">
            <a href={props.url} target="blank">
            <div className="chip">
                <img src={"icons/" + props.icon + ".svg"} alt=""/>
            </div>
            </a>
        </div>
    )
}

export default Social
